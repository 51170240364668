<template>
  <div class="container_con">
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="首营产品审批" name="1">
          <Commodity ref="model1" />
        </el-tab-pane>
        <el-tab-pane label="首营企业审批" name="2">
          <Enterprise ref="model2" />
        </el-tab-pane>
        <el-tab-pane  label="首营客户审批" name="3">
          <Client ref="model3" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Commodity from "@/components/camp/commodity";
import Enterprise from "@/components/camp/enterprise";
import Client from "@/components/camp/client";
export default {
  name: "camp",
  components: {
    Commodity,
    Enterprise,
    Client,
  },
  data() {
    return {
      activeName: "1",
    };
  },
  created() {
    this.activeName = sessionStorage.getItem("ACTIVE")
      ? sessionStorage.getItem("ACTIVE")
      : this.activeName;
    this.$nextTick(()=>{
        this.$refs['model'+this.activeName].getList()
    })
    this.old_active=this.activeName;
  },
  watch: {
    $route(to,form) {
        this.$refs['model'+this.old_active].save_filter();
    },
  },
  methods: {
    handleClick() {
      sessionStorage.setItem("ACTIVE", this.activeName);
      this.$refs['model'+this.activeName].getList()
      this.$refs['model'+this.old_active].save_filter();
      this.old_active=this.activeName;
    },
  },
};
</script>

<style lang="scss" scoped></style>
