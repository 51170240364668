<template>
  <div class="container_con">
    
      <div class="search_box">
        <div style="margin-top:0px">
            <span class="mr_20">
              单据编号：
              <el-input
                v-model="queryParams.product_no"
                placeholder="请输入单据编号"
                style="width:245px"
              />
            </span>
            <span class="mr_20">
              品名：
              <el-input
                v-model="queryParams.product_name"
                placeholder="请输入品名"
                style="width:245px"
              />
            </span>
            <span class="mr_20">
              类别：
              <el-select v-model="queryParams.type" placeholder="请选择" style="width:245px" filterable>
                  <el-option :label="item.title" v-for="(item,index) in lblist" :key="index"
                      :value="item.id"></el-option>
              </el-select>
            </span>
            <!-- <span class="mr_20">
              审批部门：
              <el-cascader style="width:245px" v-model="queryParams.department_id" :options="bmlist"
                  :props="{ expandTrigger: 'hover',checkStrictly: true,emitPath:false,value:'id',label:'title',children:'_child'}"
                  clearable></el-cascader>
            </span>
            -->
            <span class="mr_20">
              申请时间：
              <el-date-picker
                v-model="queryParams.time"
                type="daterange"  value-format="YYYY-MM-DD" 
                placeholder="请选择时间"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </span>
          <el-button type="primary" @click="handleQuery()">搜索</el-button>
          <el-button @click="resetQuery()">重置</el-button>
        </div>
      </div>
      <div style="margin-top:20px">
          <!-- <el-button type="primary" icon="el-icon-printer" v-if="have_auth('/camp/print')">打印</el-button> -->
          <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/camp/export')" :loading="is_export" @click="try_export">导出</el-button>
          <el-button type="primary" @click="$router.push('/addCommodity')" v-if="have_auth('/camp/add')"
            >+ 新增</el-button
          >
      </div>
      <div style="margin-top:20px">
        <el-table :data="list" v-loading="loading" style="width: 100%">
          <el-table-column prop="product_no" label="产品编号" />
          <el-table-column prop="product_name" label="品名" />
          <el-table-column prop="register_cert_name" label="注册证名称"/>
          <el-table-column prop="register_cert_no" label="注册证号" />
          <el-table-column prop="register_cert_expire" label="注册证到期时间" />
          <el-table-column prop="model" label="型号" />
          <el-table-column prop="spec_value_name" label="规格" />
          <el-table-column fixed="right" label="操作" width="150">
            <template v-slot="scope">
              <el-button type="text" size="small" @click="handleDetail(scope.row)" v-if="have_auth('/camp/view')">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
  </div>
  <pagination
    v-show="total>0"
    :total="total"
    v-model:page="queryParams.page"
    v-model:limit="queryParams.size"
    @pagination="getList"
  />
</template>

<script>
export default {
  name: "warrant",
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
          page: 1,
          size: 10,
          time:''
      },
      total:0,
      list:[],
      lblist:[],
      bmlist:[],
      is_export:false
    };
  },
  created() {
      this.queryParams=window['camp_commodity'] || {
        page: 1,
        size: 10,
        time:''
      }
      this.getOptions()
  },
  methods: {
      save_filter(){
          window['camp_commodity']=this.queryParams
      },
      try_export(){
          if(this.is_export){return false;}
          this.is_export = true;
          let _data={...this.queryParams};
          _data.startTime=_data.time?_data.time[0]:'';
          _data.endTime=_data.time?_data.time[1]:'';
          this.$httpGet("/backend/product.ProductFirst/export", _data).then((res) => {
              if (res.status == 200) {
                    var a = document.createElement('a');
                    let name=res.data.file.split('/')
                    var fileName = name[name.length-1];
                    a.download = fileName;
                    a.href = this.$http+'/'+res.data.file;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
              } else {
                  this.$message.error(res.message);
              }
              this.is_export = false;
          }).catch((err) => {
              console.log(err);
              this.is_export = false;
          });
      },
      // 查看详情
      handleDetail(row){
          this.$router.push('/campproductDetail?id='+row.id)
      },
      /** 查询列表 */
      getList() {
          this.loading = true;
          let _data={...this.queryParams};
          _data.startTime=_data.time?_data.time[0]:'';
          _data.endTime=_data.time?_data.time[1]:'';
          this.$httpGet("/backend/product.ProductFirst/index",_data ).then((res) => {
              if (res.status == 200) {
                  this.loading = false;
                  this.list = res.data.data;
                  this.total = res.data.total;
              } else {
                  this.$message.error(res.message);
              }
              this.loading = false;
          }).catch((err) => {
              console.log(err);
              this.loading = false;
          });
      },
      getOptions(){
          this.$httpGet("/backend/product.ProductType/index", {
              size: 10000000
          }).then((res) => {
              if (res.status == 200) {
                  this.lblist = res.data.data;
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
          
          // this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
          //     if (res.status == 200) {
          //         this.bmlist = res.data.department_list;
          //     } else {
          //         this.$message.error(res.message);
          //     }
          // }).catch((err) => {
          //     console.log(err);
          // });
      },
      /** 搜索按钮操作 */
      handleQuery() {
          this.queryParams.page = 1;
          this.getList();
      },
      resetQuery(){
          this.queryParams={
              page:1,
              size:10,
              time:''
          }
          this.getList();
      }
  },
};
</script>

<style lang="scss" scoped>
.search_box {
  background: #f2f3f5;
  border-radius: 2px;
  padding: 15px;
}
.mr_20 {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 15px;
}
</style>
